<template>
	<div class="popup  fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-3 z-10
				m-lg:min-w-[60vw] m-md:w-11/12"
	     v-click-outside="close">
		<div class="bg-white-0 p-4 rounded-xl border border-gray-600 flex flex-col gap-4">
			<div class="flex gap-8 justify-between">
				<h2 class="text-2xl" v-html="title"/>
				<button @click="close" class="float-right w-6">
					<img alt="Close" src="~/assets/icons/cross.svg" width="24" height="24">
				</button>
			</div>
			<slot/>
		</div>
		<div v-for="part in otherParts"
		     class="bg-white-0 p-4 rounded-xl border border-gray-600 flex flex-col gap-4">
			<component :is="part"/>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	title: {
		type: String,
		required: true,
	},
	otherParts: {
		type: Array,
	}
});

const { setPopup } = usePopup();

const isCaptchaOpen = ref(false);

onMounted(() => {
	setInterval(() => {
		const iframe = document.querySelector("iframe[title='Main content of the hCaptcha challenge']");
		if (iframe) {
			const div = iframe.parentElement.parentElement;
			if (div.style.visibility === "visible") {
				isCaptchaOpen.value = true;
				return;
			}
		}
		isCaptchaOpen.value = false;
	}, 200);
})

function close() {
	if (isCaptchaOpen.value) {
		return;
	}
	setPopup(null);
}
</script>

<style lang="scss" scoped>
.popup {
  min-width: 35vw;

  @media (max-width: 1024px) {
    min-width: 60vw;
  }
}
</style>